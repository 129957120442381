<template>
  <div class="container">
    <!-- Login Form -->
    <img :src="logoSrc" alt="Logo" height="100" class="mr-2 backlight" />
    <div class="logo-title roboto">CRC Load Board</div>
    <Card>
      <template #content>
        <form @submit.prevent="handleLogin">
          <div class="flex flex-col-left gap-2">
            <label for="username">Email</label>
            <InputText id="username" v-model="username" required class="w-full" />
          </div>
          <div class="flex flex-col-left gap-2 mt-4">
            <label for="password">Password</label>
            <InputText id="password" v-model="password" required class="w-full" type="password" />
          </div>
        <div class="flex flex-column align-items-center justify-content-center">
          <Button label="Login" type="submit" class="submit-button mt-4" :loading="isLoading" severity="primary" />
          <!-- <Button label="Register" text class="mt-2" :loading="isLoading" severity="primary" @click="testCall" /> -->
        </div>
        </form>
        <div class="flex flex-column align-items-center justify-content-center">
        <Button icon="pi pi-sun" class="mr-2" severity="secondary" text @click="toggleTheme" />
      </div>
      </template>
    </Card>

    <Toast />
    <!-- <Button label="Show" @click="show" /> -->
    <div class="footer-text mt-3">&copy; FoxSolution SRL</div>
  </div>
</template>

<script>
import { ref, onMounted, computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Card from 'primevue/card';

import { useToastService } from '@/utils/toast';
import { useActionToasts } from '@/utils/toastMessages';
//import { inject } from 'vue';
import api from '@/utils/axios';

export default {
  components: { Button, InputText, Password, Card },
  setup() {
    const toast = useToastService();
    const store = useStore();
    const router = useRouter();
    const username = ref('');
    const password = ref('');
    const isLoading = ref(false);

    const actionToasts = useActionToasts();

    const loginAttempts = ref(0);
    const lastAttemptTime = ref(null);
    const isLocked = ref(false);


    // const api = axios.create({
    //   baseURL: apiUrl,
    //   headers: {
    //       'Content-Type': 'application/json',
    //   },
    //   //withCredentials: true
    // });

    const testCall = async () => {
      // Make the call
      try {
        const response = await api.post('/users/test', 
        {
          
          email: 'egoomni@gmail.com',
          pass: 'admin123',
          name: 'Eduard Goomni',
          phone: '1234567890',
          type: 'admin'
        });
        console.log(response.data);
      } catch (error) {
        console.log('Error:', error.response || error);
      }
    };

    const checkRateLimit = () => {
      const now = Date.now();
      // Reset attempts if 15 minutes have passed
      if (lastAttemptTime.value && (now - lastAttemptTime.value) > 15 * 60 * 1000) {
        loginAttempts.value = 0;
        isLocked.value = false;
      }

      if (isLocked.value) {
        const remainingTime = Math.ceil((15 * 60 * 1000 - (now - lastAttemptTime.value)) / 60000);
        toast.showError(`Too many login attempts. Please try again in ${remainingTime} minutes.`);
        return false;
      }

      return true;
    };

    const show = () => {
      toast.showSuccess('Operation completed successfully!');

      toast.showSuccess('Your changes have been saved', 'Save Complete');

      toast.showToast({
        severity: 'success',
        summary: 'Custom Title',
        detail: 'Custom message with longer duration',
        life: 5000
      });
    };

    const toggleTheme = () => {
      const currentTheme = document.documentElement.classList.contains("dark-mode") ? "dark" : "light";
      const newTheme = currentTheme === "dark" ? "light" : "dark";
      document.documentElement.classList.toggle("dark-mode");
      store.dispatch('changeTheme', newTheme);
    };

    const handleLogin = async () => {
      if (!checkRateLimit()) return;

      try {
        isLoading.value = true;
        loginAttempts.value++;
        lastAttemptTime.value = Date.now();

        const response = await api.post('/users/login', {
          email: username.value,
          pass: password.value
        });

        const data = response.data;
        console.log('Login response:', data);

        if (data.success) {
          // Reset attempts on successful login
          loginAttempts.value = 0;
          isLocked.value = false;

          await store.dispatch('users/loginUser', data);
          actionToasts.loginSuccess();
          router.push('/boards');
        } else {
          // Handle rate limit response from backend
          if (data.type === 'rate_limit') {
            isLocked.value = true;
            toast.showError(data.message);
            return;
          }

          const errorMessage = data.message || 'Login failed';
          if (data.type === 'pass') {
            toast.showError(errorMessage);
          } else {
            actionToasts.loginError();
          }
        }
      } catch (error) {
        console.error('Login error:', error);

        // Enhanced error handling
        if (error.code === 'ERR_NETWORK') {
          toast.showError('Server connection failed. Please check your network connection.');
        } else {
          toast.showError(error.response?.data?.message || 'Login failed. Please try again later.');
        }
      } finally {
        isLoading.value = false;
      }
    };

    const handleSave = async () => {
      try {
        await saveData();
        actionToasts.savedSuccessfully();
      } catch (error) {
        actionToasts.operationFailed(error.message);
      }
    };

    const logoSrc = computed(() => {
      return store.state.isTheme === 'dark' ? require('@/assets/logo_white.svg') : require('@/assets/logo_black.svg');
    });

    onMounted(() => {
      //fetchClientStats();
      store.dispatch('initializeTheme').then(() => {
        const isTheme = localStorage.getItem('isTheme');
        if (isTheme === 'dark') {
          document.documentElement.classList.add("dark-mode");
        } else {
          document.documentElement.classList.remove("dark-mode");
        }
      });
    });

    return {username, password, isLoading, handleLogin, toggleTheme, logoSrc, show, testCall };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-group {
  margin-bottom: 20px;
}

.submit-button {
  width: 100%;
}

.logo-title {
  color: #000;
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: 900;
}

.dark-mode .logo-title {
  color: #84cc16;
}

.footer-text {
  color: var(--p-gray-400);
}

.dark-mode .footer-text {
  color: var(--p-gray-500);
}
</style>
