import api from '@/utils/axios';

export default {
  namespaced: true,

  state: {
    loadsData: {},
    loading: false
  },

  getters: {
    getLoadsData: state => state.loadsData,
    isLoading: state => state.loading
  },

  mutations: {
    SET_LOADS_DATA(state, data) {
      state.loadsData = data;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    RESET_LOADS_DATA(state) {
      state.loadsData = {};
    }
  },

  actions: {
    async fetchLoads({ commit, rootState, rootGetters, dispatch }, { boardId, filters }) {
      commit('SET_LOADING', true);
      try {
        const token = localStorage.getItem('lbToken');
        
        if (!boardId) {
          console.error('No board selected');
          return;
        }
        
        const response = await api.post('/data/get', {
          token: token,
          sources: 'haully,superdispatch,centraldispatch',
          action: 'getLoads',
          boardID: boardId
        });
        
        if (response.data.success) {
          console.log('Received loads data:', response.data);
          commit('SET_LOADS_DATA', response.data);
          
          // Ensure filters are loaded
          await dispatch('filters/fetchUserFilters', null, { root: true });
          
          // Get all filters from the filters store using rootGetters
          const allFilters = rootGetters['filters/allFilters'];
          console.log('All filters from getter:', allFilters);
          
          // Make sure we have filters before proceeding
          if (!allFilters || !Array.isArray(allFilters)) {
            console.error('No filters available or filters not in correct format');
            return;
          }
          
          // Dispatch to loadMatches store with both current filters and all filters
          if (filters && Array.isArray(filters)) {
            await dispatch('loadMatches/updateMatches', {
              loads: response.data.data,
              filters: filters,
            }, { root: true });
          }

          // Dispatch to loadMatches store with both current filters and all filters
          await dispatch('loadMatches/updateAllMatches', {
            loads: response.data.data,
            allFilters: allFilters
          }, { root: true });

        } else {
          console.error('Error fetching loads:', response.data.message);
          commit('RESET_LOADS_DATA');
        }
        return response.data;
      } catch (error) {
        console.error('Error fetching loads:', error);
        commit('RESET_LOADS_DATA');
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};
