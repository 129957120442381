import api from '@/utils/axios';

export default {
  namespaced: true,

  state: {
    filters: [],
    loading: false,
    error: null
  },

  getters: {
    // Get all filters
    allFilters: state => [...state.filters],
    
    // Get filters for a specific board
    filtersByBoardId: state => boardId => {
      console.log('Filtering for board ID:', boardId);
      console.log('Available filters:', state.filters);
      return state.filters.filter(filter => {
        const filterBoardId = filter.bID || filter.boardId;
        return filterBoardId === boardId;
      });
    },
    
    // Get loading state
    isLoading: state => state.loading,
    
    // Get error state
    getError: state => state.error
  },

  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters;
      console.log('Updated filters:', state.filters);
    },
    
    ADD_FILTER(state, filter) {
      state.filters.push(filter);
    },
    
    UPDATE_FILTER(state, updatedFilter) {
      const index = state.filters.findIndex(f => f.id === updatedFilter.id);
      if (index !== -1) {
        state.filters.splice(index, 1, updatedFilter);
      }
    },
    
    DELETE_FILTER(state, filterId) {
      state.filters = state.filters.filter(f => f.id !== filterId);
    },
    
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    
    SET_ERROR(state, error) {
      state.error = error;
    }
  },

  actions: {
    // Fetch all filters for current user
    async fetchUserFilters({ commit }) {
      console.log("Fetching filters...");
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);
      
      try {
        const token = localStorage.getItem('lbToken');
        const response = await api.post('/filters/manage', {
          token: token,
          action: 'getUserFilters'
        });

        if (response.data.success) {
          console.log("Filters: ", response.data.filters);
          commit('SET_FILTERS', response.data.filters);
        } else {
          commit('SET_ERROR', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    // Add a new filter
    async addFilter({ commit, dispatch }, filterData) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);
      console.log(filterData);

      try {
        const token = localStorage.getItem('lbToken');
        const response = await api.post('/filters/manage', {
          token: token,
          action: 'addFilter',
          boardID: filterData.boardId,
          filterData: {
            fPickupLocation: filterData.pickupCity,
            fPickupLat: filterData.pickupLat,
            fPickupLng: filterData.pickupLng,
            fDeliveryLocation: filterData.deliveryCity,
            fDeliveryLat: filterData.deliveryLat,
            fDeliveryLng: filterData.deliveryLng
          }
        });

        if (response.data.success) {
          // Refresh all filters to ensure consistency
          await dispatch('fetchUserFilters');
        } else {
          commit('SET_ERROR', response.data.message);
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    // Update an existing filter
    async updateFilter({ commit, dispatch }, { filterId, filterData }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      try {
        const token = localStorage.getItem('lbToken');
        const response = await api.post('/filters/manage', {
          token: token,
          action: 'updateFilter',
          filterID: filterId,
          boardID: filterData.boardId,
          filterData: {
            fPickupLocation: filterData.pickupCity,
            fPickupLat: filterData.pickupLat,
            fPickupLng: filterData.pickupLng,
            fDeliveryLocation: filterData.deliveryCity,
            fDeliveryLat: filterData.deliveryLat,
            fDeliveryLng: filterData.deliveryLng
          }
        });

        if (response.data.success) {
          // Refresh all filters to ensure consistency
          await dispatch('fetchUserFilters');
        } else {
          commit('SET_ERROR', response.data.message);
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    // Delete a filter
    async deleteFilter({ commit, dispatch }, { filterId, boardId }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      try {
        const token = localStorage.getItem('lbToken');
        const response = await api.post('/filters/manage', {
          token: token,
          action: 'deleteFilter',
          filterID: filterId,
          boardID: boardId
        });

        if (response.data.success) {
          // Refresh all filters to ensure consistency
          await dispatch('fetchUserFilters');
        } else {
          commit('SET_ERROR', response.data.message);
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};
