import { findMatches } from '@/utils/loadMatcher';

const state = {
    allMatches: [],    // Matches from all loads against all filters
    matches: [],       // Matches for current board
    previousMatches: [] // For tracking changes
};

const mutations = {
    SET_MATCHES(state, matches) {
        state.matches = matches;
        state.previousMatches = state.matches;
        console.log('Updated matches:', state.matches);
    },
    SET_ALL_MATCHES(state, matches) {
        state.allMatches = matches;
        console.log('Updated allMatches:', state.allMatches);
    }
};

const actions = {
    async updateMatches({ commit }, { loads, filters }) {
        const matches = findMatches(loads, filters);
        commit('SET_MATCHES', matches);
    },

    async updateAllMatches({ commit, state }, { loads, allFilters }) {
        // Request notification permission if not granted
        if (Notification.permission === 'default') {
            await Notification.requestPermission();
        }

        // Get matches for all loads against all filters
        console.log('Updating allMatches...');
       // console.log('Loads:', loads);
        console.log('allFilters:', allFilters);
        const allMatches = findMatches(loads, allFilters);
        
        // Compare total number of matches to detect changes
        if (Notification.permission === 'granted') {
            const currentTotalMatches = state.allMatches.length;
            const newTotalMatches = allMatches.length;

            // Only show notification if the total number of matches has changed
            if (newTotalMatches !== currentTotalMatches) {
                const notification = new Notification('Matches Updated!', {
                    body: `Total matches: ${newTotalMatches} (${newTotalMatches > currentTotalMatches ? '+' : ''}${newTotalMatches - currentTotalMatches})`,
                    icon: '/android-chrome-192x192.png'
                });
                
                // Close notification after 5 seconds
                setTimeout(() => notification.close(), 5000);
            }
        }

        commit('SET_ALL_MATCHES', allMatches);
    }
};

const getters = {
    // Get matches for a specific board from allMatches
    getMatchesByBoard: (state) => (boardId) => {
        return state.allMatches.filter(match => match.boardIds.includes(boardId));
    },

    // Get matches by type from current matches
    getMatchesByType: (state) => (type) => {
        return state.matches.filter(match => match.match === type);
    },

    // Get total number of unique matches from current matches
    getTotalMatches: (state) => {
        return state.matches.length;
    },

    // Get total number of matches by type from current matches
    getTotalMatchesByType: (state) => (type) => {
        return state.matches.filter(match => match.match === type).length;
    },

    // Get total matches for a board from current matches
    getTotalMatchesForBoard: (state) => (boardId) => {
        return state.matches.filter(match => match.boardIds.includes(boardId)).length;
    },

    // Get all matches across all boards
    getAllMatches: (state) => {
        return state.allMatches;
    },

    // Get all matches for a specific board
    getAllMatchesForBoard: (state) => (boardId) => {
        return state.allMatches.filter(match => match.boardIds.includes(boardId));
    },

    // Get total number of full matches across all boards
    getTotalFullMatchesAcrossBoards: (state) => {
        return state.allMatches.filter(match => match.match === 'full').length;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
