<template>
  <Menubar :model="items">
    <template #start>
        <!-- <div class="logo mr-2 backlight"></div> -->
      <img :src="logoSrc" alt="Logo" height="30" class="mr-2 backlight" />
    </template>
    <template #end>
      <!-- <Button v-if="!isLoggedIn" label="Login" icon="pi pi-sign-in" @click="login" class="p-button-text" /> -->
      <Button icon="pi pi-sun" class="mr-2" severity="secondary" text @click="toggleTheme" />
      <Button v-if="isLogged" label="Logout" icon="pi pi-sign-out" @click="logout" class="p-button-text" />
    </template>
  </Menubar>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Menubar from 'primevue/menubar';
import Button from 'primevue/button';

export default {
  components: { Menubar, Button },
  setup() {
    const router = useRouter();
    const store = useStore();

    // Get user data from store
    const user = computed(() => store.state.users.user);
    const isLogged = computed(() => store.state.users.user.isLogged);
    const isAdmin = computed(() => store.state.users.user.type);

    let items = [
      {
        label: 'Boards',
        icon: 'pi pi-fw pi-clipboard',
        command: () => router.push('/boards'),
        visible: computed(() => isLogged) // Only show if logged in
      },
      {
        label: 'Map',
        icon: 'pi pi-fw pi-map',
        command: () => router.push('/map'),
        visible: computed(() => isLogged) // Only show if logged in
      },
      {
        label: 'Account',
        icon: 'pi pi-fw pi-user',
        command: () => router.push('/account'),
        visible: computed(() => isLogged) // Only show if logged in
      }
    ];

    //console.table(user.value.type);
    if (user.value.type == 1){
      console.log('did test');
      items.push({
        label: 'Admin',
        icon: 'pi pi-fw pi-users',
        command: () => router.push('/admin')
      });
    }

    const logout = () => {
      store.dispatch('users/logoutUser'); // Dispatch logout action
      router.push('/');
    };

    const logoSrc = computed(() => {
      return store.state.isTheme === 'dark' ? require('@/assets/logo_white.svg') : require('@/assets/logo_black.svg');
    });

    const toggleTheme = () => {
      const currentTheme = document.documentElement.classList.contains("dark-mode") ? "dark" : "light";
      const newTheme = currentTheme === "dark" ? "light" : "dark";
      document.documentElement.classList.toggle("dark-mode");
      store.dispatch('changeTheme', newTheme);
    };

    return { items, isLogged, logout, logoSrc, toggleTheme };
  },
};
</script>

<style>
.mr-2 {
  margin-right: 0.5rem;
}
.logo{
    height: 40px;
    width: 40px;
    background-image: url("@/assets/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 3;
}
.backlight:after{
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
    
    position: absolute;
    background: inherit;
    filter: blur(7px);
    z-index: 0;
    opacity: 1;
}
</style>
