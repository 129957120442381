export default{
    namespaced: true,
    state: {
      //navigation modals
        sidebar: false,
      //general modals
        contact: false,
      //account modals  
        login: false,
        logout: false,
        register: false,
        restore: false,
        password: false,
        phone: false,
        email: false,
        filterDrivers: false,
        prompts: false,
        addBoard: false,
        createFilter: false,
        editFilter: false,
        editBoard: false,
        addUser: false,
        editUser: false,
        changePassword: false,
        addTruck: false,
        editTruck: false,
    },
    getters: {
    },
    mutations: {
      showModal(state, payload) {
        console.log("showing modal: ",payload );
        state[payload] = true;
      },
      hideModal(state, payload) {
        state[payload] = false;
      },
      toggleModal(state, modalName) {
        state[modalName] = !state[modalName];
      },
    },
    actions: {
    },
    modules: {
    }
  }