import { useToast } from 'primevue/usetoast';

export const useToastService = () => {
    const toast = useToast();

    const showToast = ({
        severity = 'info',
        summary = '',
        detail = '',
        life = 3000,
        group = null,
        closable = true
    }) => {
        toast.add({
            severity,
            summary,
            detail,
            life,
            group,
            closable
        });
    };

    // Convenience methods for common toast types
    const showSuccess = (message, title = 'Success') => {
        showToast({
            severity: 'success',
            summary: title,
            detail: message
        });
    };

    const showError = (message, title = 'Error') => {
        showToast({
            severity: 'error',
            summary: title,
            detail: message,
            life: 5000 // Errors shown longer by default
        });
    };

    const showInfo = (message, title = 'Info') => {
        showToast({
            severity: 'info',
            summary: title,
            detail: message
        });
    };

    const showWarning = (message, title = 'Warning') => {
        showToast({
            severity: 'warn',
            summary: title,
            detail: message,
            life: 4000
        });
    };

    return {
        showToast,
        showSuccess,
        showError,
        showInfo,
        showWarning
    };
}; 