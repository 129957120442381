// Helper function to calculate distance between two points using Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
              Math.sin(dLon/2) * Math.sin(dLon/2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
}

function toRad(degrees) {
    return degrees * (Math.PI/180);
}

// Check if two locations are close enough (within maxDistance km)
function areLocationsClose(loc1Lat, loc1Lng, loc2Lat, loc2Lng, maxDistance = 400) {
    return calculateDistance(loc1Lat, loc1Lng, loc2Lat, loc2Lng) <= maxDistance;
}

// Extract state from location string
function extractState(location) {
    const stateMatch = location.match(/[A-Z]{2}/);
    return stateMatch ? stateMatch[0] : null;
}

// Main matching function
export function findMatches(loads, filters) {
    // Create a map to store matches by loadId
    const matchesMap = new Map();
    
    // Process each load
    for (const source in loads) {
        for (const load of loads[source]) {
            // Process against each filter
            for (const filter of filters) {
                let matchType = null;
                
                // Extract states for route matching
                const loadPickupState = extractState(load.pickupLocation);
                const loadDeliveryState = load.deliveryLocation ? extractState(load.deliveryLocation) : null;
                const filterPickupState = extractState(filter.fPickupLocation);
                const filterDeliveryState = extractState(filter.fDeliveryLocation);
                
                // Check for full match - only if delivery location exists
                if (loadDeliveryState && loadPickupState === filterPickupState && 
                    loadDeliveryState === filterDeliveryState) {
                    // Verify exact location match using coordinates
                    const pickupClose = areLocationsClose(
                        parseFloat(load.origin_lat), 
                        parseFloat(load.origin_lng),
                        parseFloat(filter.fPickupLat), 
                        parseFloat(filter.fPickupLng),
                        400 // Stricter distance for full match
                    );
                    
                    const deliveryClose = areLocationsClose(
                        parseFloat(load.destination_lat), 
                        parseFloat(load.destination_lng),
                        parseFloat(filter.fDeliveryLat), 
                        parseFloat(filter.fDeliveryLng),
                        100 // Stricter distance for full match
                    );
                    
                    if (pickupClose && deliveryClose) {
                        matchType = 'full';
                    }
                }
                
                // Check for partial match if not already a full match
                if (!matchType) {
                    if (loadPickupState === filterPickupState) {
                        // Verify pickup location match using coordinates
                        const pickupClose = areLocationsClose(
                            parseFloat(load.origin_lat), 
                            parseFloat(load.origin_lng),
                            parseFloat(filter.fPickupLat), 
                            parseFloat(filter.fPickupLng),
                            400 // Distance for pickup match
                        );

                        if (pickupClose) {
                            // If filter has no delivery location, it's a match based on pickup only
                            if (!filter.fDeliveryLocation) {
                                matchType = 'partial';
                            } 
                            // If filter has delivery location, check delivery match
                            else if (load.deliveryLocation) {
                                const deliveryClose = areLocationsClose(
                                    parseFloat(load.destination_lat), 
                                    parseFloat(load.destination_lng),
                                    parseFloat(filter.fDeliveryLat), 
                                    parseFloat(filter.fDeliveryLng),
                                    400 // More lenient distance for partial match
                                );
                                
                                if (deliveryClose) {
                                    matchType = 'partial';
                                }
                            }
                        }
                    }
                }
                
                // If we found a match, add or update it in the map
                if (matchType) {
                    const distance = (load.deliveryLocation && filter.fDeliveryLocation) ? calculateDistance(
                        parseFloat(load.destination_lat),
                        parseFloat(load.destination_lng),
                        parseFloat(filter.fDeliveryLat),
                        parseFloat(filter.fDeliveryLng)
                    ) : null;

                    const matchKey = `${load.loadId}-${source}-${matchType}`;
                    if (!matchesMap.has(matchKey)) {
                        matchesMap.set(matchKey, {
                            match: matchType,
                            boardIds: [filter.bID],
                            loadId: load.loadId,
                            source: source,
                            distance: distance,
                            pickupLocation: load.pickupLocation,
                            deliveryLocation: load.deliveryLocation
                        });
                    } else {
                        // Add this board ID to existing match if not already present
                        const existingMatch = matchesMap.get(matchKey);
                        if (!existingMatch.boardIds.includes(filter.bID)) {
                            existingMatch.boardIds.push(filter.bID);
                        }
                    }
                    
                    // Log the match for debugging
                    console.log('Match found:', {
                        type: matchType,
                        boardId: filter.bID,
                        loadId: load.loadId,
                        source: source,
                        pickupLocation: load.pickupLocation,
                        deliveryLocation: load.deliveryLocation,
                        filterPickup: filter.fPickupLocation,
                        filterDelivery: filter.fDeliveryLocation
                    });
                }
            }
        }
    }
    
    // Convert map values to array
    return Array.from(matchesMap.values());
}
