import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: { showNavBar: false, showCanvasBG: true }
  },  
  {
    path: '/boards',
    name: 'boards',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "boards" */ '../views/BoardsView.vue'),
    meta: { showNavBar: true, showCanvasBG: false }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/AccountView.vue'),
    meta: { showNavBar: true, showCanvasBG: false }
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '../views/MapView.vue'),
    meta: { showNavBar: true, showCanvasBG: false }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/AdminView.vue'),
    meta: { showNavBar: true, showCanvasBG: false, requiresAdmin: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard
router.beforeEach((to, from, next) => {
  // Check if user is logged in
  const isLoggedIn = store.state.users.user.isLogged;
  const isAdmin = store.state.users.user.type === 1;

  // If route requires auth and user is not logged in
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next({ name: 'login' });
  }
  // If route requires admin and user is not admin
  else if (to.matched.some(record => record.meta.requiresAdmin) && (!isLoggedIn || !isAdmin)) {
    next({ name: 'boards' }); // Redirect to boards if not admin
  }
  else {
    next();
  }
});

export default router



// const routes = [
//   { path: '/', name: 'home', component: HomeView },
//   { path: '/clients', name: 'clients', component: ClientsView },
//   { path: '/clients/new', name: 'new-client', component: ClientFormView },
//   { path: '/clients/:id/edit', name: 'edit-client', component: ClientFormView },
// ]
