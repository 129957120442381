import { useToastService } from './toast';

export const useActionToasts = () => {
    const toast = useToastService();

    return {
        savedSuccessfully: () => toast.showSuccess('Changes saved successfully'),
        deletedSuccessfully: () => toast.showSuccess('Item deleted successfully'),
        operationFailed: (error) => toast.showError(error || 'Operation failed. Please try again.'),
        loginSuccess: () => toast.showSuccess('Welcome back!'),
        loginError: () => toast.showError('Invalid credentials'),
        sessionExpired: () => toast.showWarning('Your session has expired. Please login again.'),
        // Add more common messages as needed
    };
}; 