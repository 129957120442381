import { createStore } from 'vuex';
import users from './modules/users';
import modals from './modules/modals';
import filters from './modules/filters';
import loadMatches from './modules/loadMatches';
import loads from './modules/loads';

export default createStore({
  state: {
    isTheme: "light",
  },
  getters: {
  },
  mutations: {
    setTheme(state, theme) {
      state.isTheme = theme
    }
  },
  actions: {
    initializeTheme({ commit }) {
      const storedTheme = localStorage.getItem('isTheme');
      if (storedTheme) {
        commit('setTheme', storedTheme);
      } else {
        localStorage.setItem('isTheme', 'light');
        commit('setTheme', 'light');
      }
    },
    changeTheme({ commit }, theme) {
      localStorage.setItem('isTheme', theme);
      commit('setTheme', theme);
    }
  },
  modules: {
    users,
    modals,
    filters,
    loadMatches,
    loads
  }
});
