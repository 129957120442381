import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
//primeflex.css
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

//createApp(App).use(store).use(router).mount('#app')

const app = createApp(App);

// Provide apiUrl to all components
//app.provide('apiUrl', apiUrl);
///how to use it in a component
//const apiUrl = inject('apiUrl');

const MyPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{lime.50}',
            100: '{lime.100}',
            200: '{lime.200}',
            300: '{lime.300}',
            400: '{lime.400}',
            500: '{lime.500}',
            600: '{lime.600}',
            700: '{lime.700}',
            800: '{lime.800}',
            900: '{lime.900}',
            950: '{lime.950}'
        }
    }
});
// Set Axios as a global property
//app.config.globalProperties.$http = axios;

app.use(PrimeVue, {
    theme: {
        preset: MyPreset,
        options: {
            //prefix: 'p',
            //darkModeSelector: 'system',
            darkModeSelector: '.dark-mode',
            cssLayer: false
            // cssLayer: {
            //     name: 'primevue',
            //     order: 'tailwind-base, primevue, tailwind-utilities'
            // }
        }
    }
});

app.use(store);
app.use(router);
app.use(VueAxios, axios);
//app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'
app.use(ToastService);
app.use(ConfirmationService);
app.component('Toast', Toast);
app.mount('#app');
