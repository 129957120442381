<template> 
    <NavBar v-if="$route.meta.showNavBar"/>
    <router-view></router-view>
    <canvas id="canvas" v-show="$route.meta.showCanvasBG" ></canvas>
</template>

<script>
import NavBar from '@/components/NavBar.vue'; // Import NavBar component
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import api from '@/utils/axios';


function initBG(){
var canvas = document.getElementById("canvas");
var ctx = canvas.getContext('2d');

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

var stars = [], // Array that contains the stars
    FPS = 60, // Frames per second
    x = 100, // Number of stars
    mouse = {
      x: 0,
      y: 0
    };  // mouse location

// Push stars to array

for (var i = 0; i < x; i++) {
  stars.push({
    x: Math.random() * canvas.width,
    y: Math.random() * canvas.height,
    radius: Math.random() * 1 + 1,
    vx: Math.floor(Math.random() * 50) - 25,
    vy: Math.floor(Math.random() * 50) - 25
  });
}

// Draw the scene

function draw() {
  ctx.clearRect(0,0,canvas.width,canvas.height);
  
  ctx.globalCompositeOperation = "lighter";
  
  for (var i = 0, x = stars.length; i < x; i++) {
    var s = stars[i];
  
    ctx.fillStyle = "#84cc16";
    ctx.beginPath();
    ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
    ctx.fill();
    ctx.fillStyle = '#84cc16';
    ctx.stroke();
  }
  
  ctx.beginPath();
  for (var i = 0, x = stars.length; i < x; i++) {
    var starI = stars[i];
    ctx.moveTo(starI.x,starI.y); 
    if(distance(mouse, starI) < 150) ctx.lineTo(mouse.x, mouse.y);
    for (var j = 0, x = stars.length; j < x; j++) {
      var starII = stars[j];
      if(distance(starI, starII) < 150) {
        //ctx.globalAlpha = (1 / 150 * distance(starI, starII).toFixed(1));
        ctx.lineTo(starII.x,starII.y); 
      }
    }
  }
  ctx.lineWidth = 0.1;
  ctx.strokeStyle = 'gray';
  ctx.stroke();
}

function distance( point1, point2 ){
  var xs = 0;
  var ys = 0;
 
  xs = point2.x - point1.x;
  xs = xs * xs;
 
  ys = point2.y - point1.y;
  ys = ys * ys;
 
  return Math.sqrt( xs + ys );
}

// Update star locations

function update() {
  for (var i = 0, x = stars.length; i < x; i++) {
    var s = stars[i];
  
    s.x += s.vx / FPS;
    s.y += s.vy / FPS;
    
    if (s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
    if (s.y < 0 || s.y > canvas.height) s.vy = -s.vy;
  }
}

canvas.addEventListener('mousemove', function(e){
  mouse.x = e.clientX;
  mouse.y = e.clientY;
});

// Update and draw

function tick() {
  draw();
  update();
  requestAnimationFrame(tick);
}

tick();
}

export default {
  name: 'App',
  components: { NavBar },
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    console.log(process.env.VUE_APP_API_URL);
    const checkUser = (token) =>{
      console.log("Validating Token..");
      console.log("Token:" + localStorage.lbToken);
      if(!localStorage.lbToken){
        console.log("user not logged!");
        return;
      }
      console.log("Token:" + token);
      //begin post --------------------------------------------
      api
        .post('/users/validate', {
          token: localStorage.lbToken,
        })
        .then((response) => {
          console.log("RESPONCE:" + JSON.stringify(response));
          if (response.data.success) {
            store.commit("users/validateUser", response.data.data);
            store.dispatch('filters/fetchUserFilters');
            // Get current route name and redirect if on login page
            const currentRouteName = route.name;
            if (currentRouteName === "login") {
              router.push({ name: "boards" });
            }
          }else{
            console.log("Token not valid!");
            router.push({ name: "login" });
          }
        })
        .catch((error) => {
          console.log("token error", error);
        });
        //end post-------------------------------------------------
    }
    onMounted (() => {
      console.log('Application mounted');
      console.log(process.env.VUE_APP_API_URL);
      checkUser("some token");
      initBG();
      store.dispatch('initializeTheme').then(() => {
        const isTheme = localStorage.getItem('isTheme');
        if (isTheme === 'dark') {
          document.documentElement.classList.add("dark-mode");
        } else {
          document.documentElement.classList.remove("dark-mode");
        }
      });
    })
  },
  //option api
  mounted() {
    console.log(`the component is now mounted.`);
  }
  //when mounted

}
</script>

<style>
/* main color #7ed0db */
:root {
  --primary-500: var(--p-lime-500);
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --background-color: #f5f5f5;
  --text-color: #333333;
  --primary-500: #3498db;
  --gary-200: var(--p-slate-200);
}

.dark-mode {
  --primary-500: var(--p-lime-500);
  --secondary-color: #27ae60;
  --accent-color: #c0392b;
  --background-color: #2c3e50;
  --text-color: #ecf0f1;
  --gary-200: var(--p-slate-200);
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

.roboto {
  font-family: 'Roboto Condensed', sans-serif;
}


.flex-col-left{
  flex-direction: column;
  align-items: flex-start;
}

body{
  /* background: url('assets/topography.png'); */
  position: relative;
}

#canvas {
 width: 100%;
 height: 100%;
 top:0;
 left:0;
  position: absolute;
  z-index: -1;
}

</style>
